import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import App from './App.vue'
import axios from 'axios'
import router from '@/router/index.js'
// import "../public/js/TweenMax.min.js";

Vue.use(ElementUI);
Vue.use(ViewUI);

Vue.config.productionTip = false

let echarts = require('echarts')

let BASE_URL_SERVER = "https://apishanghai.safesrcc.com";


Vue.prototype.$echarts = echarts;
axios.defaults.withCredentials = false;
Vue.prototype.$axios = axios;
Vue.prototype.$url = BASE_URL_SERVER;
Vue.prototype.$static_url = "https://shanghai.safesrcc.com/";


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

export {BASE_URL_SERVER}


