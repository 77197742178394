//基础框架
const mainFrame = () => import('@/components/MainFrame.vue');
const manageFrame = () => import('@/components/ManageFrame.vue');
const searchResult = () => import('@/components/Common/SearchResult.vue');

//主页
const homeCarousel = () => import('@/components/IndexPage/HomeCarousel.vue');
const indexContent = () => import('@/components/IndexPage/IndexContent.vue');

const analysisPanel = () => import('@/components/AnalysisPanel.vue');

//登录及账户管理
const loginBox = () => import('@/components/Account/Login.vue');
const accountCenter = () => import('@/components/Account/AccountCenter.vue');

//后台管理
const menuBar = () => import('@/components/MenuBar.vue');
const riskResult = () => import('@/components/DataManage/RiskResult.vue');
const userManage = () => import('@/components/DataManage/UserManage.vue');
const roleManage = () => import('@/components/DataManage/RoleManage.vue');
const accidentManage = () => import('@/components/DataManage/AccidentManage.vue');
const backupManage = () => import('@/components/DataManage/BackupManage.vue');
const correctManage = () => import('@/components/DataManage/CorrectManage.vue');
const expertManage = () => import('@/components/DataManage/ExpertManage.vue');
const latentDangerManage = () => import('@/components/DataManage/LatentDangerManage.vue');
const lineManage = () => import('@/components/DataManage/LineManage.vue');
const netManage = () => import('@/components/DataManage/NetManage.vue');
const netOpManage = () => import('@/components/DataManage/NetOpManage.vue');
const operationManage = () => import('@/components/DataManage/OperationManage.vue');
const periodRiskManage = () => import('@/components/DataManage/PeriodRiskManage.vue');
const reportManage = () => import('@/components/DataManage/ReportManage.vue');
const resultRiskManage = () => import('@/components/DataManage/ResultRiskManage.vue');
const ruleManage = () => import('@/components/DataManage/RuleManage.vue');
const emergencyManage = () => import('@/components/DataManage/EmergencyManage.vue');
const emergencyGoodsManage = () => import('@/components/DataManage/EmergencyGoodsManage.vue');
const transferManage = () => import('@/components/DataManage/TransferManage.vue');
const emergencySchemeManage = () => import('@/components/DataManage/EmergencySchemeManage.vue');
const emergencyPlanManage = () => import('@/components/DataManage/EmergencyPlanManage.vue');
const emergencyRecordManage = () => import('@/components/DataManage/EmergencyRecordManage.vue');
const areaEmergencyManage = () => import('@/components/DataManage/AreaEmergencyManage.vue');

// const backUp = () => import('@/components/AdminPanel/BackUp.vue');
// const riskEntry = () => import('@/components/AdminPanel/RiskEntry.vue');
// const lineEntry = () => import('@/components/AdminPanel/LineEntry.vue');
// const reportEntry = () => import('@/components/AdminPanel/ReportEntry.vue');
// const baseEntry = () => import('@/components/AdminPanel/BaseEntry.vue');
// const accidentEntry = () => import('@/components/AdminPanel/AccidentEntry.vue');
// const expertEntry = () => import('@/components/AdminPanel/ExpertEntry.vue');
// const passengerFlowEntry = () => import('@/components/AdminPanel/PassengerFlowEntry.vue');
// const riskCorrectEntry = () => import('@/components/AdminPanel/RiskCorrectEntry.vue');
// const dataEntryMenuBar = () => import('@/components/AdminPanel/DataEntryMenuBar.vue');
// const correctEntry = () => import('@/components/AdminPanel/CorrectEntry.vue');
// const latentDangerEntry = () => import('@/components/AdminPanel/LatentDangerEntry.vue');

//基础数据
const lineList = () => import('@/components/BaseInfo/LineList.vue');
const lineContent = () => import('@/components/BaseInfo/LineContent.vue');
const netDescription = () => import('@/components/BaseInfo/NetDescription.vue');
const indexPanel = () => import('@/components/OperationData/IndexPanel.vue');
const indexData = () => import('@/components/OperationData/IndexData.vue');

//网络化运营
const emergency = () => import('@/components/NetworkOperation/Emergency.vue');
const center = () => import('@/components/NetworkOperation/Center.vue');
const passengerFlow = () => import('@/components/NetworkOperation/PassengerFlow.vue');

//期间数据
const accidentList = () => import('@/components/PeriodData/Accident/AccidentList.vue');
const accidentContent = () => import('@/components/PeriodData/Accident/AccidentContent.vue');
const latentDangerList = () => import('@/components/PeriodData/LatentDanger/LatentDangerList.vue');
const periodRiskList = () => import('@/components/PeriodData/PeriodRisk/PeriodRiskList.vue');

//安评结果
const riskList = () => import('@/components/RiskResult/Risk/RiskList.vue');
const reportList = () => import('@/components/RiskResult/Report/ReportList.vue');
const riskContent = () => import('@/components/RiskResult/Risk/RiskContent.vue');
const reportContent = () => import('@/components/RiskResult/Report/ReportContent.vue');
const riskNav = () => import('@/components/RiskResult/Risk/RiskNav.vue');
const correctList = () => import('@/components/RiskResult/Correct/CorrectList.vue');

//专家
const expertMajor = () => import('@/components/ExpertSystem/ExpertMajor.vue');

//资料档案
const ruleList = () => import('@/components/Archive/RuleList.vue');

export default [
    //注册和登录
    {
        path:"/login",
        name:"login",
        component:loginBox,
    },
    
    //主页数据
    {
        path:"/",
        name:"home",
        component:mainFrame,
        children:[
            {
                path:"/index",
                name:"index",
                components:{
                default:indexContent,
                indexCarousel:homeCarousel
            },
            },
            {
                path:"/index_n",
                name:"operationData",
                components:{
                    default:indexData,
                    indexCarousel:indexPanel
                },
            },
            {
                path:"/accountCenter",
                name:"accountCenter",
                components:{
                default:accountCenter,
            },
            },
            /*{
                path:"/chengdu",
            name:"index_chengdu",
            components:{
                default:indexData,
                indexCarousel:indexPanel
            },
            }*/
            
        ]
        
    },
    {
        path:"/analysis",
        name:"analysis",
        component:analysisPanel,
    },
    //主页搜索
    {
        path:"/search",
        name:"search",
        component:mainFrame,
        children:[
            {
                path:"search_result",
                name:"search_result",
                component:searchResult,
            },
        ]
    },
    //基础数据
    {
        path:'/baseInfo',
        name:'baseInfo',
        component:mainFrame,
        children:[
            {
                path:"line_l",
                name:"line_l",
                component:lineList,
            },
            {
                path:"line_c/:line_code",
                name:"line_c",
                component:lineContent,
                props:true

            },
            {
                path:"netDescription",
                name:"netDescription",
                component:netDescription,
                props:true
            },
            {
                path:"operationData",
                name:"operationData",
                components:{
                    default:indexData,
                    indexCarousel:indexPanel
                },
            }          
        ]
    },
    //网络化运营
    {
        path:'/network',
        name:'newwork',
        component:mainFrame,
        children:[
            {
                path:"emergency",
                name:"emergency",
                component:emergency,
            },
            {
                path:"center",
                name:"center",
                component:center,
            }, 
            {
                path:"passengerFlow",
                name:"passengerFlow",
                component:passengerFlow,
            },           
        ]
    },
    //评估结论数据
    {
        path:'/conclusion',
        name:'conclusion',
        component:mainFrame,
        children:[
            {
                path:"risk_l",
                name:"risk_l",
                component:riskList,
            },
            {
                path:"correct_l",
                name:"correct_l",
                component:correctList,
            },
            {
                path:"report_l",
                name:"report_l",
                component:reportList,
            },
            {
                path:"risk_c/:risk_id",
                name:"risk_c",
                component:riskContent,
                props:true
            },
            {
                path:"report_c/:report_id",
                name:"report_c",
                component:reportContent,
                props:true
            }, 
            {
                path:"/risk_nav",
                name:"risk_nav",
                component:riskNav,
            },
        ]
    },
    //运行期间数据
    {
        path:'/period',
        name:'period',
        component:mainFrame,
        children:[
            {
                path:"latent_danger_l",
                name:"latent_danger_l",
                component:latentDangerList,
            },
            {
                path:"accident_l",
                name:"accident_l",
                component:accidentList,
            },
            {
                path:"accident_c/:accident_id",
                name:"accident_c",
                component:accidentContent,
                props:true
            },
            {
                path:"period_risk",
                name:"period_risk",
                component:periodRiskList,
            },   
        ]
    },
    //专家和档案资料
    {
        path:'/archive',
        name:'archive',
        component:mainFrame,
        children:[
            {
                path:"expert/:major",
                name:"expert",
                component:expertMajor,
                props:true
            },
            {
                path:"rule_l",
                name:"rule_l",
                component:ruleList,
            },           
        ]
    },
    //数据后台管理
    {
        path:"/manage",
        name:'manage',
        component:manageFrame,
        children:[
            {
                path:'riskEntry',
                name:'riskEntry',
                components:{
                    default:riskResult,
                    leftBar:menuBar,
                }
            },
            {
                path:'userManage',
                name:'userManage',
                components:{
                    default:userManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'roleManage',
                name:'roleManage',
                components:{
                    default:roleManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'accident',
                name:'accidentManage',
                components:{
                    default:accidentManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'backup',
                name:'backupManage',
                components:{
                    default:backupManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'correct',
                name:'correctManage',
                components:{
                    default:correctManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'expert',
                name:'expertManage',
                components:{
                    default:expertManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'latentDanger',
                name:'latentDangerManage',
                components:{
                    default:latentDangerManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'line',
                name:'lineManage',
                components:{
                    default:lineManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'net',
                name:'netManage',
                components:{
                    default:netManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'netOp',
                name:'netOpManage',
                components:{
                    default:netOpManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'operation',
                name:'operationManage',
                components:{
                    default:operationManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'periodRisk',
                name:'periodRiskManage',
                components:{
                    default:periodRiskManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'report',
                name:'reportManage',
                components:{
                    default:reportManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'resultRisk',
                name:'resultRiskManage',
                components:{
                    default:resultRiskManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'rule',
                name:'ruleManage',
                components:{
                    default:ruleManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'emergency',
                name:'emergencyManage',
                components:{
                    default:emergencyManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'transfer',
                name:'transferManage',
                components:{
                    default:transferManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'emergencyGoods',
                name:'emergencyGoodsManage',
                components:{
                    default:emergencyGoodsManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'emergencyPlan',
                name:'emergencyPlanManage',
                components:{
                    default:emergencyPlanManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'emergencyRecord',
                name:'emergencyRecordManage',
                components:{
                    default:emergencyRecordManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'emergencyScheme',
                name:'emergencySchemeManage',
                components:{
                    default:emergencySchemeManage,
                    leftBar:menuBar,
                }
            },
            {
                path:'areaEmergency',
                name:'areaEmergencyManage',
                components:{
                    default:areaEmergencyManage,
                    leftBar:menuBar,
                }
            },

        ]

    },
    //数据后台管理
    // {
    //     path:"/entry",
    //     name:"entry",
    //     component:mainFrame,
    //     children:[
    //         {
    //             path:"riskEntry",
    //             name:"riskEntry",
    //             components:{
    //                 default:riskEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"correctEntry",
    //             name:"correctEntry",
    //             components:{
    //                 default:correctEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"latentDangerEntry",
    //             name:"latentDangerEntry",
    //             components:{
    //                 default:latentDangerEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"lineEntry",
    //             name:"lineEntry",
    //             components:{
    //                 default:lineEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"reportEntry",
    //             name:"reportEntry",
    //             components:{
    //                 default:reportEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"accidentEntry",
    //             name:"accidentEntry",
    //             components:{
    //                 default:accidentEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"riskCorrectEntry",
    //             name:"riskCorrectEntry",
    //             components:{
    //                 default:riskCorrectEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"baseEntry",
    //             name:"baseEntry",
    //             components:{
    //                 default:baseEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"expertEntry",
    //             name:"expertEntry",
    //             components:{
    //                 default:expertEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"passengerFlowEntry",
    //             name:"passengerFlowEntry",
    //             components:{
    //                 default:passengerFlowEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         /*{
    //             path:"riskCorrectPlanEntry",
    //             name:"riskCorrectPlanEntry",
    //             components:{
    //                 default:riskCorrectPlanEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"riskReviewEntry",
    //             name:"riskReviewEntry",
    //             components:{
    //                 default:riskReviewEntry,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },*/
    //         // {
    //         //     path:"aps",
    //         //     name:"aps",
    //         //     components:{
    //         //         default:alterPassword,
    //         //         leftBar:dataEntryMenuBar
    //         //     }
    //         // },
    //         {
    //             path:"backup",
    //             name:"backup",
    //             components:{
    //                 default:backUp,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },
    //         {
    //             path:"rule_m",
    //             name:"rule_m",
    //             components:{
    //                 default:ruleManage,
    //                 leftBar:dataEntryMenuBar
    //             }
    //         },

    //     ]

    // },
    
]